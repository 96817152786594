define("discourse/plugins/paczki-topic-promo/discourse/initializers/admin-menu-topic-promo-button", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "I18n"], function (_exports, _ajax, _ajaxError, _pluginApi, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "admin-menu-topic-promo-button";
  function initialize(api) {
    api.modifyClass("component:topic-list-item", {
      pluginId: PLUGIN_ID,
      classNameBindings: ["topic.topic_promoted", "topic.topic_promoted_in_group", "topic.closed"]
    });

    // topic_promoted
    api.addTopicAdminMenuButton(topic => {
      const canManageTopic = api.getCurrentUser()?.canManageTopic;
      if (!topic.isPrivateMessage && canManageTopic) {
        return {
          icon: topic.get("topic_promoted") ? "volume-mute" : "volume-up",
          label: topic.get("topic_promoted") ? "topic_promo.remove_highlight" : "topic_promo.highlight",
          action: () => {
            const dialog = api.container.lookup("service:dialog");
            const topicController = api.container.lookup("controller:topic");
            const data = {
              custom_field: "topic_promoted"
            };
            toggleHighlight(topic, dialog, topicController, data);
          }
        };
      }
    });

    // topic_promoted_in_group
    api.addTopicAdminMenuButton(topic => {
      const canManageTopic = api.getCurrentUser()?.canManageTopic;
      if (!topic.isPrivateMessage && canManageTopic) {
        return {
          icon: topic.get("topic_promoted_in_group") ? "volume-mute" : "volume-up",
          label: topic.get("topic_promoted_in_group") ? "topic_promo.group.remove_highlight" : "topic_promo.group.highlight",
          action: () => {
            const dialog = api.container.lookup("service:dialog");
            const topicController = api.container.lookup("controller:topic");
            const data = {
              custom_field: "topic_promoted_in_group"
            };
            toggleHighlight(topic, dialog, topicController, data);
          }
        };
      }
    });
  }
  function toggleHighlight(topic, dialog, topicController, data) {
    dialog.yesNoConfirm({
      message: topic.get(data.custom_field) ? _I18n.default.t("topic_promo.dialog.remove_highlight") : _I18n.default.t("topic_promo.dialog.highlight"),
      didConfirm: () => {
        (0, _ajax.ajax)(`/topic/highlight/${topic.id}`, {
          type: "POST",
          data
        }).then(res => {
          topic.set(data.custom_field, res.highlight);
          topicController.set("editingTopic", false);
          topic.postStream.posts[0].rebake();
        }).catch(_ajaxError.popupAjaxError);
      }
    });
  }
  var _default = _exports.default = {
    name: "admin-menu-topic-promo-button",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.topic_promo_enabled) {
        (0, _pluginApi.withPluginApi)("0.12.1", initialize);
      }
    }
  };
});
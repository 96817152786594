define("discourse/plugins/paczki-topic-promo/discourse/initializers/add-class-to-topic-list-item", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const PLUGIN_ID = "add-class-to-topic-list-item";
  function initialize(api) {
    api.modifyClass("component:topic-list-item", {
      pluginId: PLUGIN_ID,
      didInsertElement() {
        this._super(...arguments);

        // const router = api.container.lookup("service:router");
        // if (!router.currentRouteName.startsWith("discovery")) {
        //   return;
        // }

        if (this.topic?.topic_promoted) {
          this.element.classList.add("topic-promoted");
        }
        if (this.topic?.topic_promoted_in_group) {
          this.element.classList.add("topic-promoted-in-group");
        }
      }
    });
  }
  var _default = _exports.default = {
    name: "add-class-to-topic-list-item",
    initialize(container) {
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.topic_promo_enabled) {
        (0, _pluginApi.withPluginApi)("0.8.6", initialize);
      }
    }
  };
});